import axios from 'axios'
import store from '@/store/store'
export default () => {
  return axios.create({
    baseURL: 'https://192.168.0.15:4000/',
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  })
}
